.nopage-cont {
  width: 100%;
  height: 100vh;
  background-color: black;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nopage-cont .box {
  display: flex;
  align-items: center;
  height: 41px;
  color: white;
}
.nopage-cont .box h1 {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 23px 10px 0;
  margin-right: 20px;
  font-size: 24px;
  font-weight: 500;
}
