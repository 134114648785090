/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.input-container .filled {
  transform: translate(0px, 6px);
  font-size: 12px;
}
.input-container input {
  border: 2px solid rgba(0, 0, 0, 0.12);
  padding: 20px;
  padding-bottom: 10px;
  outline: none;
  box-shadow: none;
  background: #f3f4f5;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.86);
}

.input-container input:not(:placeholder-shown) ~ label,
.input-container input:-webkit-autofill ~ label{
  transform: translate(0px, 6px);
  font-size: 12px;
}

.input-container input:focus {
  border: 2px solid #16aa51;
}
.input-container input::-webkit-outer-spin-button, .input-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-container label {
  position: absolute;
  pointer-events: none;
  height: 100%;
  transform: translate(0, 35%) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  left: 20px;
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.36);
}
.input-container:focus-within label {
  transform: translate(0px, 6px);
  font-size: 12px;
  color: #16aa51;
}
.textarea-container {
  width: 100%;
  position: relative;
}
.textarea-container textarea {
  resize: none;
  /* width: calc(100% - 40px); */
  width: 100%;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.12);
  background-color: #f3f4f5;
  padding: 24px 20px;
  height: 120px;
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.86);
}
.textarea-container .textarea-label {
  position: absolute;
  font-family: "Gentona Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.36);
  margin: 8px 20px;
}
.textarea-container textarea:focus {
  border: 2px solid #16aa51;
}
.textarea-container:focus-within label {
  color: #16aa51;
}
.dropdown-main {
  position: relative;
}
.dropdown-main .drop-downWraper {
  /* width: calc(100% - 42px); */
  height: 56px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  padding: 0 20px;
  background: #f3f4f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-main .drop-downWraper .country-right {
  margin-top: -4px;
}
.dropdown-main .drop-downWraper .country-right .country-name {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.36);
  margin-top: 6px;
}
.dropdown-main .drop-downWraper .country-right .country-title {
  margin-top: -2px;
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.86);
}
.dropdown-main .drop-downWraper .down-arrow {
  cursor: pointer;
}
.dropdown-main .dropdown-container-desk {
  position: absolute;
  display: block;
  top: 56px;
  z-index: 10;
  width: calc(100% - 32px);
  background: #fff;
  height: 304px;
  padding: 28px 16px;
  overflow: scroll;
  filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.15));
}
@media only screen and (max-width: 500px) {
  .dropdown-main .dropdown-container-desk {
    /* display: none; */
 }
}
.dropdown-main .dropdown-container-desk .each-country {
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.86);
  margin-top: 32px;
  cursor: pointer;
}
.dropdown-main .dropdown-container-desk > .each-country:nth-of-type(1) {
  margin-top: 0px;
}
.dropdown-main .hide-generic-model-desk {
  display: none;
}
@media only screen and (max-width: 500px) {
  .dropdown-main .hide-generic-model-desk {
    display: block;
 }
}
.dropdown-main .dropdown-container-mob {
  display: none;
  background-color: white;
}
@media only screen and (max-width: 500px) {
  .dropdown-main .dropdown-container-mob {
    display: block;
    position: fixed;
    height: 341px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 16px;
 }
}
.dropdown-main .dropdown-container-mob .dropdown-Box .country-header {
  margin-top: 32px;
  font-family: "Gentona";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.86);
}
.dropdown-main .dropdown-container-mob .dropdown-Box .country-list {
  overflow: scroll;
  height: 272px;
  width: 100%;
}
.dropdown-main .dropdown-container-mob .dropdown-Box .country-list .country-selector {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.86);
}
.dropdown-main .dropdown-container-mob .dropdown-Box .country-list::-webkit-scrollbar {
  display: none;
}
