.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  text-transform: none;
  font-family: "Gentona";
}
.popup_overlay .pop_container {
  background-color: #fff;
  padding: 32px 40px 48px;
  text-align: left;
  position: absolute;
  width: 424px;
  margin-right: auto;
}
.popup_overlay .pop_container .cross-icon-wrapper {
  width: 100%;
  text-align: right;
}
.popup_overlay .pop_container .cross-icon-wrapper .cross_icon {
  cursor: pointer;
}
.popup_overlay .pop_container .page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #000;
  margin-top: 24px;
}
.popup_overlay .pop_container .page-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  margin-top: 8px;
}
.popup_overlay .pop_container .input-container {
  margin-top: 23px;
}
.popup_overlay .pop_container .reset-button {
  width: 100%;
  height: 54px;
  margin-top: 32px;
  background-color: #000;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 520px) {
  .popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: block;
    z-index: 3;
    text-transform: none;
    font-family: "Gentona";
 }
  .popup_overlay .pop_container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 32px 16px;
    width: calc(100% - 32px);
    height: auto;
    font-family: "Gentona";
 }
  .popup_overlay .pop_container .page-title {
    font-size: 24px;
    line-height: 32px;
 }
}
