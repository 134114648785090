@font-face {
    font-display: block;
    font-family: "Gentona Light";
    src: local("Gentona Light"), url(./assets/fonts/Gentona-Light.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona ExtraLight";
    src: local("Gentona ExtraLight"), url(./assets/fonts/Gentona-ExtraLight.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona Extrabold";
    src: local("Gentona ExtraBold"), url(./assets/fonts/Gentona-ExtraBold.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona Medium";
    src: local("Gentona Medium"), url(./assets/fonts/Gentona-Medium.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona Medium Italic";
    src: local("Gentona Medium Italic"), url(./assets/fonts/Gentona-MediumItalic.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona Semibold";
    src: local("Gentona Semibold"), url(./assets/fonts/Gentona-SemiBold.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona Bold";
    src: local("Gentona Bold"), url(./assets/fonts/Gentona-Bold.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona Extrabold";
    src: local("Gentona ExtraBold"), url(./assets/fonts/Gentona-ExtraBold.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona Medium";
    src: local("Gentona Medium"), url(./assets/fonts/Gentona-Medium.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona";
    src: local("Gentona"), url(./assets/fonts/Gentona-Book.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona Italic";
    src: local("Gentona Italic"), url(./assets/fonts/Gentona-BookItalic.otf) format("opentype");
}
@font-face {
    font-display: block;
    font-family: "Gentona SemiBoldItalic";
    src: local("Gentona SemiBoldItalic"), url(./assets/fonts/Gentona-SemiBoldItalic.otf) format("opentype");
}
body {
    margin: 0;
    font-family: "Gentona";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}
