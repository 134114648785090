body {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
  background-color: #f5f5f5;
  position: relative;
  font-family: "LG Smart", "Segoe UI", "Microsoft Sans Serif", sans-serif;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

div#wrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

#wrapper c {
  max-width: 1440px;
  margin: 0 auto 50px;
}

#wrapper .header_kv img {
  width: 100%;
}

.header {
  width: 100%;
  height: auto;
  /* padding: 40px 0; */
  text-align: center;
}

h1.logo {
  margin: 0;
  padding: 0;
  margin-top: 40px;
}

.section {
  width: 100%;
  max-width: 1100px;
  height: auto;
  margin: 0 auto;
}

.booklist {
  width: 100%;
  height: auto;
}

.booklist > li {
  float: left;
  width: calc(100% / 3);
  height: 255px;
  text-align: center;
  margin: 40px 0 20px 0;
}

.booklist > li img {
  width: 95%;
  max-width: 230px;
  height: 233px;
  border: 1px solid #e7dada;
  box-shadow: 9px 8px 4px #8b7c7c;
  border-radius: 5px;
  display: inline-block;
}

.booklist > li img:hover {
  box-shadow: 4px 4px 4px #5e4b4b;
  transition: 0.5s;
}

.booklist > li p {
  width: 100%;
  margin: 15px 0 0;
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.booklist::after {
  display: block;
  content: "";
  clear: both;
}

.footer {
  width: 100%;
  margin: 0 0 0 0;
  border-top: 1px solid #d0d0d0;
  background-color: #333;
}

.footer > p {
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  padding: 40px 60px;
  color: rgb(250, 250, 250);
  text-align: -webkit-center;
  /* font-family: "LG Smart", "Segoe UI", "Microsoft Sans Serif", sans-serif; */
}

.header_kv h2 {
  padding: 0px;
  margin: 0px;

  font-size: 48px;
  font-weight: bold;
  text-align: center;
  font-family: "LG Smart", "Segoe UI", "Microsoft Sans Serif", sans-serif;
  color: #333333;
}

@media screen and (max-width: 869px) {
  h2.title2 {
    margin: 0 0 0 20px;
  }

  .booklist {
    width: calc(100% - 20px);
    padding: 0 10px;
  }

  .booklist > li {
    width: calc(100% / 2);
  }

  .topmenu > li {
    width: 50%;
  }

  .topmenu > li a {
    width: 50%;
  }

  footer > p {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .header_kv h2 {
    font-size: 30px;
  }
}
