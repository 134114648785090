.cg-day-2024-section-title{
    width:100%;
    margin-top:10px;
    text-align:center;
}

.slick-prev {
    left: 20px;
    /*to notice it, is white*/
}

.slick-next {
    right: 20px;
    /*to notice it, is white*/
}

.cg-day-2024-title{
    font-size:30px;
    font-weight:bold;
    color:#1b3d78;
}

.cgday-2024-banner{
    margin:10px 0;
}

.cgday-2024-banner img{
    width:100%;
}

.cgday-2024-static-coupons{
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    column-gap: 1.3%;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: flex-start;
    margin:20px 0;
}

.cg-day-2024-coupon-wrapper{
    width:24%;
    display:flex;
}

.coupon-card {
    background: linear-gradient(135deg, #0171b6, #1b3d78);
    color: #fff;
    text-align: center;
    padding: 5px 25px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    width:100%;
    display:flex;
    flex-direction:row;
    column-gap:10px;
}

.cg-day-2024-discount-percent{
    display:flex;
    align-items: center;
    font-size:50px;
    font-weight:bold;
}

.cg-day-2024-discount-percent-and-countdown{
    display:flex;
    flex-direction:column;
    align-items: center;
    font-weight:bold;
    justify-content: center;
}

.cg-day-2024-countdown{
    display:flex;
    flex-direction:row;
    column-gap:5px;
}

.cg-day-2024-countdown-components{
    display:flex;
    flex-direction:column;
    row-gap:2px;
}

.cg-day-2024-countdown-component-title{
    font-size:10px;
}

.cg-day-2024-countdown-component-time{
    background:#fff;
    color:#1b3d78;
    padding:0 5px;
    font-size:16px;
    border:solid 3px #1b3d78;
}

.cg-day-2024-discount-contents{
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
}

.coupon-card h3 {
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    margin-top:5px !important;
    margin-bottom:5px !important;
}

.coupon-card p {
    font-size: 10px;
}

.coupon-row {
    display: flex;
    align-items: center;
    margin: 10px auto;
    width: fit-content;
}

#cpnCode {
    border: 1px dashed #fff;
    padding: 5px;
    background:#fff;
    font-weight:bold;
    color:#1b3d78;
    position:relative;
}

#cpnBtn {
    border: 1px solid #fff;
    background: #fff;
    padding: 10px 20px;
    color: #7158fe;
    cursor: pointer;
}

.circle1,
.circle2 {
    background: #f5f7f8;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.circle3 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 3%;
    left: -4px;
}

.circle4 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 12%;
    left: -4px;
}

.circle5 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 21%;
    left: -4px;
}

.circle6 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 30%;
    left: -4px;
}

.circle7 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 3%;
    left: -4px;
}

.circle8 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 12%;
    left: -4px;
}

.circle9 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 21%;
    left: -4px;
}

.circle10 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 30%;
    left: -4px;
}

.circle11 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 3%;
    right: -4px;
}

.circle12 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 12%;
    right: -4px;
}

.circle13 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 21%;
    right: -4px;
}

.circle14 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 30%;
    right: -4px;
}

.circle15{
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 3%;
    right: -4px;
}

.circle16 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 12%;
    right: -4px;
}

.circle17 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 21%;
    right: -4px;
}

.circle18 {
    background: #f5f7f8;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 30%;
    right: -4px;
}

.circle1 {
    left: -25px;
}

.circle2 {
    right: -25px;
}

.cgday-2024-double-banner{
    width:100%;
    margin:30px 0;
    display:flex;
    flex-direction:row;
    column-gap:10px;
    flex-wrap:nowrap;
}

.cg-day-2024-double-banner-container{
    width:50%;
}

.cg-day-2024-double-banner-container img{
    width:100%;
}

.cg-day-2024-category-container{
    width:100%;
    margin:20px 0;
    display:flex;
    flex-direction:row;
    column-gap:1.3%;
    flex-wrap:wrap;
    row-gap:10px;
}

.cg-day-2024-category-grid{
    display:flex;
    flex-direction:row;
    background:#fff;
    width:24%;
    padding:5px 10px;
    border-radius:5px;
    box-shadow: 0px 1px 2px 0px #dddddd;
    cursor:pointer;
}

.cg-day-2024-category-grid:hover {
    box-shadow: 0px 2px 2px 0px #b3b3b3c7;
}

.cg-day-2024-category-thumb{
    display:flex;
    align-items: center;
    padding:0 5px;
}

.cg-day-2024-category-thumb img{
    width:50px;
}

.cg-day-2024-category-title{
    display:flex;
    align-items: center;
    padding:0 10px;
    color:#337ab7;
    font-weight:bold;
}

.cg-day-2024-brands-container{
    width:100%;
    margin:20px 0;
}

.cg-day-2024-brand-thumb{
    width:80%;
}

.tooltiptext {
    color: #1b3d78 !important;
    visibility: hidden;
    width: 140px;
    background-color: #ffffff;
    color: #1b3d78;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity .3s;
}

#cpnCode:hover .tooltiptext {
    visibility: visible;
    opacity: 1
}

.coupon-row h4{
    visibility: hidden;
    display:none;
}

.cgmodalcontainer{
    position:fixed;
    width:100%;
    height:100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.6);
    left:0;
    top:0;
}

.cgmodal{
    position:fixed;
    width:90%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.cgclose{
    position:absolute;
    right:-25px;
    top:-25px;
    background:#fff;
    border:solid thin black;
    width:50px;
    height:50px;
    display:flex;
    font-weight:bold;
    justify-content: center;
    border-radius:50%;
    font-size:2em;
    cursor:pointer;
}

.modal-content{
    height:80vh;
}

.modal-content h3{
    text-align:center;
}

.coupon-see-products-btn{
    background:transparent;
    color:#fff;
    border:0;
    font-size:10px;
    text-decoration: underline;
}

.product-grid-list {
    padding: 0;
}

.product-box-list-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
}

.p-discount {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 65px;
    height: 24px;
    color: #fff;
    background: #2a5ba2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    letter-spacing: 1px;
    z-index: 99;
    border-radius: 3px;
}

.lg-img-container:hover .p-img-back {
    display: block;
}

.lg-img-container:hover .p-img-front {
    display: none;
}

.p-img a,
.p-img {
    width: 100%;
    height: 100%;
    display: flex;
}

.p-img a img {
    width: 100%;
    height: 130px;
    object-position: center;
    object-fit: contain;
    animation: lg-fade 0.3s;
}

.p-img-back {
    display: none;
}

.p-box-text {
    margin-top: 5px;
    width: 100%;
}

.product-category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.product-category span {
    color: #acacac;
    font-size: 0.9rem;
    margin: 5px 0;
}

.product-title {
    color: #1b3d78;
    font-weight: 600;
    font-size:12px;
    text-decoration: none;
    transition: all ease 0.3s;
}

.product-title:hover {
    color: #1b3d78;
    opacity: 0.7;
    transition: all ease 0.3s;
}

.price-buy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.product-xtra-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    font-size:12px;
}

.striking-price{
    text-decoration: line-through;
    color:#acacac !important;
}

.price-buy .p-price {
    font-size: 12px;
    color: #333333;
    font-weight: 700;
}

.price-buy .p-buy-btn {
    color: #1b3d78;
    margin: 0 5px;
    font-weight: 600;
    text-decoration: none;
    font-size:12px;
}

.price-buy .p-buy-btn:hover {
    text-decoration: underline;
}

@media screen and (orientation:portrait){

    .cg-day-2024-coupon-wrapper{
        width:49%;
        position:relative;
        margin-bottom:10px;
    }

    .coupon-card{
        flex-direction:column;
        padding:25px 25px 5px 25px;
    }

    .cg-day-2024-category-grid{
        width:49%;
    }

    .cgday-2024-double-banner{
        flex-direction: column;
        row-gap:20px;
    }

    .cg-day-2024-double-banner-container{
        width:100%;
    }

    .coupon-row{
        margin:5px auto;
    }

    .cg-day-2024-discount-contents p{
        margin:0 !important;
    }

    .cg-day-2024-discount-contents h3{
        margin:0 !important;
    }

    #cpnCode{
        padding:2px 5px;
        font-size:10px;
    }

    .coupon-card h3{
        font-size:12px;
        line-height:14px;
        padding-top:5px;
    }

    .coupon-card p{
        font-size:8px;
    }

    .cg-day-2024-countdown-component-time{
        font-size:12px;
    }

    .cg-day-2024-countdown-component-title{
        font-size:8px;
    }

    .cg-day-2024-discount-percent{
        font-size:16px;
        justify-content: center;
        position:absolute;
        left:50%;
        transform:translateX(-50%);
        top:-20px;
        background:#fff;
        width:40px;
        height:40px;
        border-radius:50%;
        z-index:99;
        padding:5px;
        color:#0e5595;
        border:solid 2px #0e5595;
    }
}