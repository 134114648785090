.payment-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.payment-page .product-list {
  margin-top: 32px;
}
.payment-page .product-item {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.payment-page .product-item .right-arrow {
  margin-left: auto;
}
.payment-page .product-item .logo {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.payment-page .product-item .body > div {
  display: flex;
  align-items: center;
}
.payment-page .product-item .body > div > img {
  margin-left: 8px;
  width: 18px;
  height: 18px;
}
.payment-page .product-item .body > div > span {
  font-style: normal;
  font-family: "Gentona Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #000;
}
.payment-page .product-item .body .color {
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000 8f;
}
.payment-page .customer-details {
  padding: 16px 0;
}
.payment-page .customer-details .product-item {
  border: none;
}
.payment-page .customer-details .address {
  position: absolute;
  margin-top: 8px;
  width: 280px;
}
.pay-button {
  width: 100%;
  height: 54px;
  background-color: black;
  outline: none;
  border: none;
  font-family: "Gentona medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: white;
  margin-top: auto;
  margin-bottom: 24px;
  cursor: pointer;
}
@media (max-width: 439px) {
  .payment-page .pay-button {
    margin-bottom: 8px;
 }
}
