.order-wrapper {
  font-family: "Gentona";
  padding-bottom: 90px;
}
.order-wrapper .order-title {
  font-style: normal;
  font-family: "Gentona Medium";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.86);
  margin-top: 24px;
}
.order-wrapper .order-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.56);
  margin-top: 8px;
}
.order-wrapper .break-line {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 24px;
}
.order-wrapper .all-orders .each-order {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100px;
  margin-top: 32px;
  cursor: pointer;
}
.order-wrapper .all-orders .each-order .order-details {
  display: flex;
}
.order-wrapper .all-orders .each-order .order-details .order-image {
  width: 100px;
  height: 100px;
}
.order-wrapper .all-orders .each-order .order-details .order-image > img {
  height: 100%;
  width: 100%;
}
.order-wrapper .all-orders .each-order .order-details .order-summary {
  margin-left: 16px;
}
.order-wrapper .all-orders .each-order .order-details .order-summary .order-variant {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.86);
}
.order-wrapper .all-orders .each-order .order-details .order-summary .order-id {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.56);
}
.order-wrapper .all-orders .each-order .order-details .order-summary .order-fail {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #c91a3a;
}
.order-wrapper .all-orders .each-order .order-cta {
  height: 100px;
  display: flex;
  align-items: center;
  margin-top: -5px;
}
.order-wrapper .all-orders .each-order .order-cta div {
  cursor: pointer;
}
.order-wrapper .another-order {
  position: fixed;
  bottom: 0;
  height: 56px;
  width: 416px;
  background: #f3f4f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: -23px 0 0 0 #f1f3f5, 100px 0 0 0 #f1f3f5;
  cursor: pointer;
}
@media (max-width: 439px) {
  .order-wrapper .another-order {
    width: calc(100% - 48px);
 }
}
.order-wrapper .another-title {
  font-style: normal;
  font-family: "Gentona Medium";
  font-size: 16px;
  line-height: 19px;
  color: #000;
}
