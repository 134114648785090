.newaccount-wrapper {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media (max-width: 439px) {
  .newaccount-wrapper {
    margin-top: 24px;
 }
}
.newaccount-wrapper .newaccount-title {
  font-family: "Gentona Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.86);
}
.newaccount-wrapper .inputBox-container {
  margin-top: 16px;
  margin-bottom: 24px;
}
.newaccount-wrapper .inputBox-container > div {
  margin-top: 16px;
}
.newaccount-wrapper .signup-button {
  margin-top: auto;
  margin-bottom: 24px;
}
.newaccount-wrapper .signup-button button {
  width: 100%;
  height: 54px;
  background: #000;
  border: none;
  outline: none;
  font-family: "Gentona Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
}
