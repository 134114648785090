.backButton-cont {
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.24)) drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12));
  cursor: pointer;
  z-index: 10000;
}
@media (max-width: 439px) {
  .backButton-cont {
    position: fixed;
    top: 0;
    filter: none;
    background: none !important;
    display: block;
 }
}
.backButton-cont img {
  height: 12px;
  width: 18px;
}
@media screen and (orientation:portrait) {
  .main-container .common-rightside > .backButton-cont {
    background: white !important;
    display: block;
    margin-top: 50px;
    width: 100%;
    position: fixed;
    top: 0;
    border-radius: 0 !important;
    padding: 10px;
    padding-left: 0;
}
}
