.gen-modal-comp {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 111;
    height: auto;
    width: 100%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}
.gen-modal-comp .overlay {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.gen-modal-comp .content {
    height: auto;
    max-height: 100vh !important;
}
