.shops-container{
    display:flex;
    flex-wrap:wrap;
    column-gap:2%;
    row-gap:20px;
    margin-top:20px;
    margin-bottom:40px;
}

.shop-wrapping{
    width:48%;
    padding:20px;
    display:flex;
    flex-direction:column;
    border-radius:10px;
    box-shadow:0px 2px 3px #d4d0d0;
}

.store-ko-naam{
    display:flex;
    column-gap:20px;
}

.store-ko-agadi-ko-descriptions-haru{
    font-size:18px;
    font-weight:bold;
}

.store-ko-photo img{
    width:100%;
    height:300px;
    border-radius:5px;
}

.store-ko-naam, .store-ko-photo, .store-ko-info{
    margin:5px 0;
}

.store-ko-agadi-ko-title{
    font-size:18px;
}

.store-ko-agadi-ko-desc{
    font-size:18px;
    font-weight:bold;
}

.store-ko-info-container{
    display:flex;
    column-gap:20px;
}

@media screen and (orientation:portrait){
    .shop-wrapping {
        width:100%;
    }

    .store-ko-photo img{
        height:200px;
    }

    .store-ko-info-container {
        display: flex;
        column-gap: 10px;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

        .store-ko-agadi-ko-descriptions-haru {
            font-size: 14px;
            font-weight: bold;
        }

                .store-ko-agadi-ko-title {
                    font-size: 14px;
                }
        
                .store-ko-agadi-ko-desc {
                    font-size: 14px;
                    font-weight: bold;
                }
}