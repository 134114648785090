.ps-0 {
    padding-left: 0!important;
}
.pe-0 {
    padding-right: 0!important;
}
.main-layout {
    display: block;
    justify-content: flex-end;
}
.main-layout .content-wrapper .content-header {
    border-bottom: solid 1px #4c4c4c;
    padding: 15px 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9;
    margin-left: 3px;
}
.main-layout .content-wrapper .content-header a {
    text-decoration: none;
    color: #33a368;
    font-weight: 700;
}
.main-layout .content-wrapper .content-header a i {
    padding-right: 5px;
}
.fa-left-long:before, .fa-long-arrow-alt-left:before {
    content: "\f30a";
}
.main-layout .sidebar {
    width: 300px;
    margin-top: 90px;
    position: relative;
    height: calc(100vh - 90px);
}
.main-layout .sidebar .sidebar-position {
    position: fixed;
    background: #005ba3;
    left: 0;
    top: 0;
    height: 100vh;
    width: 300px;
    padding: 45px 10px 35px 30px;
}
.main-layout .sidebar .sidebar-position .progress-indicator {
    position: relative;
}
.main-layout .sidebar .sidebar-position .progress-indicator:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: #fff;
}
.main-layout .sidebar .sidebar-position .progress-indicator .item {
    padding-left: 20px;
    margin-bottom: 35px;
    position: relative;
}

.main-layout .sidebar .sidebar-position .progress-indicator .item.complete::before {
    position: absolute;
    content: "\f00c";
    left: -4px;
    font-family: "Font Awesome 6 Pro";
    z-index: 9;
    top: 3px;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
}
.main-layout .sidebar .sidebar-position .progress-indicator .item h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}
.main-layout .sidebar .sidebar-position .progress-indicator .item h5 {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    position: relative;
    padding-left: 20px;
}
.main-layout .sidebar .sidebar-position .progress-indicator .item h5::after {
    position: absolute;
    content: "\f3bf";
    top: 0;
    left: 0;
    font-weight: 600;
    font-family: "Font Awesome 6 Pro";
    transform: rotate(90deg);
}
.main-layout .sidebar .sidebar-position .progress-indicator .item::after {
    position: absolute;
    left: -8px;
    top: 2px;
    content: "";
    height: 17px;
    width: 17px;
    border-radius: 12px;
    background-color: #fff;
}
.main-layout .sidebar .sidebar-position .progress-indicator .item.complete::after {
    background-color: #3fb577;
    border: solid 1px #1c7848;
}
.checkbox input[type=checkbox], .radio input[type=radio] {
    height: 100%;
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: 1;
}
.main-layout .content-wrapper .content-main .radio-section .radio-selections .radio label {
    width: 100%;
    position: relative;
    border: solid 1px #f1e6e6;
    padding: 20px;
    box-shadow:0 0 7px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    height: 100%;
}
.main-layout .content-wrapper .content-main .radio-section .radio-selections .radio {
    width: 23%;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.main-layout .content-wrapper .content-main .radio-section .radio-selections {
    display: flex;
    -moz-column-gap: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
}
.main-layout .content-wrapper .content-main .radio-section .radio-selections .radio label .cr.image-radio {
    position: absolute;
    top: 15px;
    left: 15px;
}
.main-layout .content-wrapper .content-main .radio-section .radio-selections .radio label .cr {
    position: relative;
    border: 1px solid #a9a9a9;
    border-radius: 24px;
    width: 20px;
    height: 20px;
    margin-right: 20px;
    flex: none;
    top: 1px;
}
.radio input[type=radio] + label .cr:after {
    opacity: 0;
}
.main-layout .content-wrapper .content-footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}
.main-layout .content-wrapper .content-main .radio-section .radio-selections .radio label .radio-captions img {
    height: 120px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    width: 100%;
    margin-bottom: 8px;
    padding: 0 30px;
}
.main-layout .content-wrapper .content-footer .footer-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    align-items: center;
    background-color: #fbf2e5;
}
.main-layout .content-wrapper .content-footer .footer-wrapper .evaluated-price {
    /* display: none; */
}
.main-layout .content-wrapper .content-footer .footer-wrapper .evaluated-price h5 {
    font-size: 14px;
    margin-bottom: 0;
    color: #9c9c9c;
    font-weight: 700;
}
.checkbox input[type="checkbox"]:hover,
.radio input[type="radio"]:hover {
    height: 100%;
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: 1;
	&:hover{
		cursor: pointer;
	}
}
.main-layout .content-wrapper .content-footer .footer-wrapper .evaluated-price h4 {
    margin-bottom: 0;
    font-weight: 800;
    color: #2c2c2c;
    font-size: 32px;
    line-height: 1.1;
}
.main-layout .content-wrapper .content-footer .footer-wrapper .next-page-info h6 {
    font-size: 15px;
    margin-bottom: 0;
    color: #4f330c;
    font-weight: 600;
}
.radio input[type="radio"]:checked+label .cr:after {
	opacity: 1;
}
.radio input[type="radio"]:checked+label .cr{
	border: solid 1px #005ba3 !important;
}
.radio.yes-selection input[type="radio"]:checked+label{
	background-color: #3cb878;
}
.radio.no-selection input[type="radio"]:checked+label{
	background-color: #d33535;
	border: solid 1px #d33535 !important;
}
.radio.partial-selection input[type="radio"]:checked+label{
	background-color: #ddc935;
	border: solid 1px #ddc935 !important;
}
.radio.yes-selection input[type="radio"]:checked+label .cr, .radio.no-selection input[type="radio"]:checked+label .cr, .radio.partial-selection input[type="radio"]:checked+label .cr{
	border: 1px solid #fff !important;
}

.radio.yes-selection input[type="radio"]:checked+label .cr::after, .radio.no-selection input[type="radio"]:checked+label .cr::after, .radio.partial-selection input[type="radio"]:checked+label .cr::after{
	background: #fff !important;
}

.radio.yes-selection input[type="radio"]:checked+label .radio-captions, .radio.no-selection input[type="radio"]:checked+label .radio-captions, .radio.partial-selection input[type="radio"]:checked+label .radio-captions{
	color: #fff !important;
}

.radio label input[type="radio"]:disabled+.cr {
	opacity: .5;
}
.main-layout .content-wrapper .content-footer .footer-wrapper .continue-btn {
    background-image: linear-gradient(250deg, #068af3, #005ba3) !important;
    color: #fff;
    text-decoration: none;
    padding: 13px 45px;
    border-radius: 30px;
}
.hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;
    background: linear-gradient(250deg, #44bd7e, #1f8550) !important;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.main-layout .content-wrapper .content-main .radio-section .radio-selections .radio label .radio-captions {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #2c2c2c;
    width: 100%;
}
.main-layout .content-wrapper .content-main .radio-section {
    margin-bottom: 30px;
}
.main-layout .content-wrapper .content-main .radio-section h3 {
    font-size: 36px;
    color: #005ba3;
    font-weight: 800;
    margin-bottom: 40px;
}
.main-layout .content-wrapper .content-main .radio-section h4 {
    font-size: 20px;
    color: #2c2c2c;
    font-weight: 700;
    margin-bottom: 25px;
}
.main-layout .content-wrapper .content-main {
    padding: 40px 0 20px 100px;
}
.main-layout .content-wrapper .content-main .radio-section .radio-selections .radio label .cr::after {
    content: "";
    position: absolute;
    height: 13px;
    width: 13px;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    background-color: #005ba3;
}
.customer-information {
    background: rgba(0, 0, 0, 0.65);
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
.fade {
    transition: opacity .15s linear;
}
.modal-dialog {
    max-width: 967px !important;
    margin: 1.75rem auto;
}
.modal.show .modal-dialog {
    transform: none;
}
.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0,-50px);
}
.customer-information .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 100%;
}
.customer-information .modal-dialog .modal-content {
    position: relative;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}
.customer-information .modal-dialog .modal-content .btn-close {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 9;
    background: #f44336;
    opacity: 1;
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-size: 20px;
    transition: ease all 0.3s;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

[type=radio]:not(:checked)+label:before{
    border:none !important;
}

[type=radio]:checked+label:before{
    border:none !important;
}

[type=radio]:checked+label:after{
    background:none !important;
    border:none !important;
}

.checkbox, .radio{
    margin-top:0 !important;
}

.customer-information .modal-dialog .modal-content .modal-body {
    padding: 0;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left {
    padding: 55px 30px 55px 40px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left a {
    text-decoration: none;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper {
    width: 100%;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left a {
    text-decoration: none;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left a h2 {
    font-size: 26px;
    color: #1f5183;
    font-weight: 700;
    margin-bottom: 15px;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information {
    background-color: #1f5183;
    border-radius: 3px;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 12px 20px;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item h3 {
    font-size: 14px;
    margin-bottom: 0;
    color: #fff;
    font-weight: 700;
    text-align: left;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item p {
    font-size: 17px;
    margin-bottom: 0;
    color: #3cb878;
    font-weight: 700;
    text-align: right;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.offer {
    background-color: #989440;
    margin-bottom: 0;
    flex-direction: column;
    align-items: baseline;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.highlighted {
    border-radius: 2px;
    background-color: #08315a;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 12px 20px;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item h3 {
    font-size: 14px;
    margin-bottom: 0;
    color: #fff;
    font-weight: 700;
    text-align: left;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.offer ul {
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    margin-bottom: 0;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.offer ul li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2px 0;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    text-align: left;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.offer ul li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2px 0;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    text-align: left;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.offer ul li span.offer-valid-till {
    color: #9e0000;
    font-size: 12px;
    display: block;
    font-weight: normal;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.offer ul li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2px 0;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    text-align: left;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.highlighted {
    border-radius: 2px;
    background-color: #08315a;
}

.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 12px 20px;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item h3 {
    font-size: 14px;
    margin-bottom: 0;
    color: #fff;
    font-weight: 700;
    text-align: left;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item.highlighted p {
    font-size: 23px;
    font-weight: 800;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 12px 20px;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item h3 {
    font-size: 14px;
    margin-bottom: 0;
    color: #fff;
    font-weight: 700;
    text-align: left;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-left .exchange-wrapper .exchange-information .item p {
    font-size: 17px;
    margin-bottom: 0;
    color: #3cb878;
    font-weight: 700;
    text-align: right;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-right {
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-right h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 45px;
    color: #1c1c1c;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-right .form-group {
    margin-bottom: 30px;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-right .form-group label {
    margin-bottom: 10px;
    font-weight: 700;
}

label {
    display: inline-block;
}
.customer-information .modal-dialog .modal-content .modal-body .modal-right .form-group .form-control {
    height: auto;
    padding: 12px 10px;
    border-radius: 3px;
    font-size: 15px;
    border: solid 1px #484848;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before, .fa-xmark:before {
    content: "\f00d";
}
.customer-information .modal-dialog .modal-content .modal-body .modal-right .btn {
    padding: 10px 25px;
    background-color: #3cb878;
    color: #fff;
    margin-top: 40px;
    border-radius: 30px;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}
@media screen and (min-width: 1200px){
.modal-dialog {
    max-width: 1050px;
    margin: 0 auto;
    width: 100%;
}
}
@media screen and (min-width: 1400px){
.modal-dialog {
    max-width: fit-content;
    width: 1100px;
}}
@media (min-width: 1200px){
.modal-dialog {
    max-width: 850px;
    margin: 1.75rem auto;
}}
@media (min-width: 576px){
.modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}}