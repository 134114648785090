.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  text-transform: none;
}
.pop_container {
  position: relative;
  background-color: #fff;
  padding: 32px 40px 48px;
  text-align: left;
  width: 364px;
}
@media (min-width: 1280px) {
  .pop_container {
    width: 465px;
 }
}
.pop_container .cross_icon {
  display: block;
  position: absolute;
  cursor: pointer;
  right: 40px;
  top: 30px;
  opacity: 0.5;
}
.pop_container .pop_header {
  font-family: "Gentona";
  font-size: 24px;
  line-height: 28px;
  color: #000;
  text-align: left;
  margin-bottom: 16px;
}
.pop_container .pop_content {
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Gentona";
  font-size: 16px;
  line-height: 26px;
}
@media screen and (max-width: 520px) {
  .popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    text-transform: none;
    font-family: "Gentona";
 }
  .pop_container {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 24px 2%;
    width: 100%;
    height: auto;
    font-family: "Gentona";
 }
}
