.left-panel {
  position: relative;
  flex: 1;
}
.left-panel a.brand {
  display: block;
  padding: 48px 32px;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.left-panel a.brand img {
  max-width: 100%;
}
.left-panel .logo-container-np {
  position: fixed;
  left: 32px;
  top: 48px;
  z-index: 2;
  cursor: pointer;
}
.left-panel .star-text-for-s1-pro {
  position: absolute;
  bottom: -50px;
  right: 31%;
  transform: translateX(-50%);
  color: #fff;
  opacity: 0.3;
  font-size: 15px;
  line-height: 28px;
}
.att-main-container-new {
  display: flex;
  justify-content: center;
  width: 100%;
}
.att-main-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.three-attributes-container {
  display: flex;
  position: fixed;
  bottom: 6.5%;
  width: 628px;
  justify-content: space-between;
}
.val-att-np {
  font-family: "Gentona Medium";
  font-size: 31.3875px;
  line-height: 28px;
  color: #fff;
  margin: 0;
}
.small-val-att-np {
  font-size: 15.3875px;
}
.arai-info {
  cursor: pointer;
  margin-left: 7px;
  margin-top: -1px;
}
.name-att-np {
  font-family: "Gentona Light";
  font-size: 17.4375px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  opacity: 0.8;
  margin: 0;
}
@media (min-width: 1025px) and (max-width: 1366px) {
  .left-panel {
    width: calc(100% - 464px);
 }
}
@media (min-width: 440px) and (max-width: 1024px) {
  .left-panel {
    width: 100%;
    height: 42vh;
    position: fixed;
    z-index: 1;
 }
}
@media (min-width: 280px) and (max-width: 439px) {
  .left-panel {
    position: relative;
 }
  .left-panel a.brand {
    display: none;
 }
}
