.login-wrapper {
  margin-top: 80px;
}
@media (max-width: 439px) {
  .login-wrapper {
    margin-top: 0px;
 }
}
.login-wrapper .login-title {
  font-family: "Gentona Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.86);
  margin-top: 24px;
}
.login-wrapper .login-desc {
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.56);
  margin-top: 8px;
  max-width: 320px;
}
.login-wrapper .input-boxes {
  margin-top: 32px;
}
.login-wrapper .input-boxes div:nth-child(2) {
  margin-top: 16px;
}
.login-wrapper .forgot-wrapper {
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 12px;
  /* margin-top: -12px; */
  font-family: "Gentona Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.56);
  position: relative;
  z-index: 2;
}
.login-wrapper .forgot-wrapper div {
  cursor: pointer;
}
.login-wrapper .buttons-container {
  position: absolute;
  bottom: 24px;
  width: calc(100% - 48px);
}
@media screen and (max-width: 520px) {
  .login-wrapper .buttons-container {
    position: fixed;
 }
}
.login-wrapper .buttons-container .login-button {
  width: 100%;
  height: 54px;
  background: #000;
  border: none;
  outline: none;
  font-family: "Gentona Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
}
.login-wrapper .buttons-container .or-cotainer {
  width: calc(100% - 2px);
  display: flex;
  justify-content: space-between;
  height: 24px;
  align-items: center;
  margin-top: 32px;
}
.login-wrapper .buttons-container .or-cotainer hr {
  width: 148px;
  opacity: 0.12;
  border: 1px solid #000;
}
.login-wrapper .buttons-container .or-cotainer .or-title {
  font-family: "Gentona";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
}
.login-wrapper .buttons-container .signup-button {
  width: 100%;
  height: 54px;
  background: #f3f4f5;
  border: none;
  outline: none;
  font-family: "Gentona Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  margin-top: 32px;
  cursor: pointer;
}

