.myreservation-cont {
  display: flex;
  position: relative;
  height: 100vh;
}
.myreservation-cont .left-panel {
  position: relative;
  flex: 1;
}
.myreservation-cont .left-panel .logo-container-np {
  position: fixed;
  left: 32px;
  top: 48px;
  z-index: 2;
  cursor: pointer;
}
@media (max-width: 439px) {
  .myreservation-cont .left-panel .logo-container-np {
    display: none;
 }
}
.myreservation-cont .left-panel .banner-img {
  background-image: url(https://olaelectric-videos.azureedge.net/olaelectric-videos/discovery-new-final/reservation/dashboard.jpg);
  height: 100%;
  flex: 1 0 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 55%;
}
.myreservation-cont .right-panel {
  padding: 0 24px;
  flex-basis: 416px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 70px;
}
.myreservation-cont .right-panel::-webkit-scrollbar {
  display: none;
}
@media (max-width: 439px) {
  .myreservation-cont .right-panel {
    margin-top: 60px;
 }
  .myreservation-cont .right-panel > .backButton-cont {
    margin-top: 24px;
    position: fixed;
    top: 0;
    filter: none;
 }
}
.myreservation-cont .right-panel > .backButton-cont {
  margin-top: 24px;
  position: fixed;
  top: 0;
}
.checkout-wrp, .order-summary {
  margin: 0;
}
.order-summary {
  padding: 10px 100px 10px 10px;
}
.order-id {
  width: 100%;
}
