.main-container {
  display: flex;
  position: relative;
  height: 100vh;
}
.main-container .common-rightside {
  padding: 0 24px;
  flex-basis: 416px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
@media (max-width: 439px) {
  .main-container .common-rightside {
    padding: 16px;
    margin-top: 54px;
 }
}
.main-container .common-rightside > .backButton-cont {
  margin-top: 24px;
}
@media (max-width: 439px) {
  .main-container .common-rightside > .backButton-cont {
    background: none;
    display: block;
    margin-top: 50px;
 }
}
