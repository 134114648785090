.desktop-slider .slick-prev, .desktop-slider .slick-next {
	 z-index: 1;
	 height: 40px;
	 width: 40px;
	 text-align: center;
	 cursor: pointer;
}
 .desktop-slider .slick-prev {
	 left: 40px;
	 top: 49.7vh;
	 position: absolute;
}
 .desktop-slider .slick-prev::before {
	 content: "";
	 position: absolute;
	 background: url(../olaAssets/banner-left-arrow.svg) top no-repeat;
	 opacity: 1;
	 height: 50px;
	 width: 40px;
	 left: 0;
	 top: 0;
}
 .desktop-slider .slick-next {
	 right: 40px;
	 top: 50vh;
	 position: absolute;
}
 .desktop-slider .slick-next::before {
	 content: "";
	 position: absolute;
	 background: url(../olaAssets/banner-right-arrow.svg) top no-repeat;
	 opacity: 1;
	 height: 50px;
	 width: 40px;
	 right: 0;
	 top: 0;
}
 .desktop-slider .slick-slide {
	 position: relative;
	 height: 100vh;
}
 .desktop-slider .slick-slide::before {
	 content: "";
	 position: absolute;
	 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
	 bottom: 0;
	 left: 0;
	 width: 100%;
	 height: 40%;
}
 .desktop-slider [class*="banner"] {
	 height: 100vh;
	 background-size: cover;
}
 .desktop-slider .carousel-image {
	 height: 100vh;
	 position: absolute;
	 z-index: 0;
	 left: 0;
	 top: 0;
	 width: 100%;
	 background-position: center !important;
	 background-repeat: no-repeat !important;
	 background-size: cover !important;
	 background: url(../olaAssets/ipadMain.png);
}
 @keyframes fadeInFirst {
	 0% {
		 opacity: 0.7;
	}
	 100% {
		 opacity: 1;
	}
}
 @keyframes fadeInSecond {
	 0% {
		 opacity: 0.7;
	}
	 100% {
		 opacity: 1;
	}
}
 .desktop-slider .slick-slide img {
	 max-width: 100%;
	 width: 100%;
}
 .desktop-slider .slick-dots li {
	 position: relative;
	 display: inline-block;
	 width: 9px;
	 height: 17px;
	 margin: 0;
	 padding: 0;
	 cursor: pointer;
}
 .desktop-slider .carousel-image {
	 height: 100vh;
}
 .desktop-slider .image-container {
	 position: relative;
}
 .desktop-slider .shadow-container {
	 position: absolute;
	 display: block;
	 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
	 opacity: 0.4;
	 width: 100%;
	 bottom: 0;
	 z-index: 0;
	 height: 292.21px;
}
 @media (min-width: 440px) and (max-width: 1024px) {
	 .desktop-slider .slick-prev {
		 top: 20.5vh;
	}
	 .desktop-slider .slick-next {
		 top: 21vh;
	}
	 .desktop-slider .slick-slide {
		 position: relative;
		 height: 42vh;
	}
	 .desktop-slider .slick-slide::before {
		 content: "";
		 position: absolute;
		 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
		 bottom: 0;
		 left: 0;
		 height: 12%;
		 top: 30vh;
	}
	 .desktop-slider [class*="banner"] {
		 height: 42vh;
		 background: url(../olaAssets/ipadMain.png) center no-repeat;
		 background-size: cover;
	}
	 .desktop-slider .carousel-image {
		 height: 100%;
		 background-size: contain !important;
		 background-repeat: no-repeat !important;
	}
	 .desktop-slider .image-container {
		 position: initial;
	}
	 .desktop-slider .shadow-container {
		 display: none;
		 position: absolute;
		 height: 200px;
	}
}
 @media (min-width: 280px) and (max-width: 439px) {
	 .desktop-slider .slick-slide {
		 height: 304px;
	}
	 .desktop-slider [class*="banner"] {
		 height: 304px;
	}
	 .desktop-slider .carousel-image {
		 height: 100%;
	}
	 .desktop-slider .slick-prev, .desktop-slider .slick-next {
		 z-index: -999;
	}
}
 .desktop-slider .slick-dots {
	 bottom: 11px;
}
 