.payment-page {
  width: 100%;
  font-family: "Gentona";
  margin-top: 80px;
}
@media (max-width: 439px) {
  .payment-page {
    margin-top: 0px;
 }
}
.payment-page .payment-title {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-family: "Gentona Medium";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000;
  margin-top: 24px;
}
.payment-page .pay-desc {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.36);
  margin-top: 32px;
}
.payment-page .break-line {
  opacity: 0.12;
  border: 0.5px solid #000;
  margin-top: 10px;
}
.payment-page .pay-options-wrapper .each-pay-option {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
}
.payment-page .pay-options-wrapper .each-pay-option .right-sideicon {
  display: flex;
}
.payment-page .pay-options-wrapper .each-pay-option .right-sideicon .pay-title {
  margin-left: 18px;
  margin-top: 2px;
}
